// import React from "react";
// import { useEffect, useState } from "react";
// import client, {urlFor} from "../../sanityCli";
// import image from './image.svg';

// function HomeSec11() {

//   const [globalData, setGlobalData] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await client.fetch('*[_type == "homeSec11"][0]');
//         setGlobalData(data);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <div className="my-[10%] flex flex-col items-center justify-center">
//      {globalData.heading && <p className="text-[#63715B] playfair-display text-[1.5rem] md:text-[3.75vw] lg:leading-[4.25vw] font-semibold">{globalData.heading}</p>}
//      {globalData.description && <p className="monstrrate font-normal lg:text-[1.8vw] lg:leading-[2.8vw] text-[#353C33] my-4 text-center px-[5%] lg:px-[10%]">
//      {globalData.description}
//       </p>}
//      {globalData.image && <img src={urlFor(image)} alt='/' className="w-4/5 lg:w-3/4 my-8"/>}
//     </div>
//   );
// }

// export default HomeSec11;

import React, { useState, useEffect } from "react";
import client, {urlFor} from "../../sanityCli";
// import "./HomeSec11.css";

function HomeSec11() {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec11"]{
          heading,
          description,
          "imageUrl": image.asset->url
        }`
      )
      .then((data) => setData(data[0]))
      .catch(console.error);
  }, []);

  if (!data) return <div></div>;

  return (
    <div className="my-[10%] flex flex-col items-center justify-center">
      <p className="heading-color playfair-display text-[1.5rem] lg:text-[3.75vw] leading-[4.25vw] font-semibold">{data.heading}</p>
      <p className="monstrrate font-normal text-[1rem] leading-6 lg:text-[1.8vw] lg:leading-[2.8vw] text-[#353C33] my-8 text-center px-[4%]">
        {data.description}
      </p>
      <img src={data.imageUrl} alt="Global Reach" className="w-full my-2" />
    </div>
  );
}

export default HomeSec11;

