import React, { useState, useEffect } from "react";
import client, { urlFor } from "../../sanityCli";
import { v4 as uuid4 } from "uuid";

function HomeSec12() {
  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    company: "",
    email: "",
    message: "",
  });

  const fetchData = async () => {
    try {
      const result = await client.fetch('*[_type == "contactSection"][0]');
      setContent(result);
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    await fetchData();
    if (!content) {
      setIsLoading(false); // Stop loading if no content
      return;
    }

    client
      .patch(content._id)
      .setIfMissing({ formSubmissions: [] })
      .append("formSubmissions", [
        {
          ...formData,
          _key: uuid4(),
          submittedAt: new Date().toISOString(),
        },
      ])
      .commit()
      .then(() => {
        alert("Form submitted successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          company: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        alert("Failed to submit the form. Please try again.");
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  if (!content) return <div></div>;

  return (
    <div
      id="contact"
      className="contact flex flex-col lg:flex-row items-start justify-start px-[5%] my-[10%] gap-20"
    >
      <div className="contact-left w-full lg:w-1/3 flex flex-col items-center lg:items-start space-y-6">
        <p className="bg-[#63715B] text-[.7rem] leading-[1rem] lg:text-[1.2vw] lg:leading-[1.75vw] text-white px-8 py-2 rounded-full">
          {content.letsConect}
        </p>
        <h1 className="text-[#63715B] monstrrate text-[1.5rem] leading-[1.7rem] lg:text-[3.5vw] lg:leading-[4vw] font-bold">
          {content.title}
        </h1>
        <p className="monstrrate text-center lg:text-start text-[.8rem] leading-[1.1rem] md:text-xl lg:text-[1.2vw] lg:leading-[1.8vw] text-[#595566]">
          {content.description}
        </p>
        <div className=" hidden lg:flex flex-col lg:items-start items-center justify-between space-y-9">
          <div className="flex items-center justify-center gap-2 lg:gap-5">
            <div>
              <img
                src={urlFor(content.phoneIcon).url()}
                alt="/"
                className="w-16"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem]">
                {content.callLable}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw]">
                {content.phone}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div>
              <img
                src={urlFor(content.dayIcon).url()}
                alt="/"
                className="w-16"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem]">
                {content.dayLabbel}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw]">
                {content.workingDays}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div>
              <img
                src={urlFor(content.officeIcon).url()}
                alt="/"
                className="w-16"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem]">
                {content.locationLable}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw]">
                {content.address}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-5">
            <div>
              <img
                src={urlFor(content.emailIcon).url()}
                alt="/"
                className="w-16"
              />
            </div>
            <div className="flex flex-col items-start ">
              <p className="monstrrate text-[.6rem] lg:text-[1.2vw] lg:leading-[2.1rem]">
                {content.emailLabel}
              </p>
              <p className="text-[#2B3029] monstrrate text-[.8rem] lg:text-[1.25vw] lg:leading-[2.2vw]">
                {content.emailAdd}
              </p>
            </div>
          </div>
        </div>
        <div className="block w-full lg:hidden">
          <div className="1-row flex items-center w-full">
            <div className="flex w-1/2 items-center justify-start gap-2 lg:gap-5">
              <div>
                <img
                  src={urlFor(content.phoneIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-start justify-center">
                <p className="monstrrate text-[.6rem]">{content.callLable}</p>
                <p className="text-[#2B3029] monstrrate text-[.8rem]">
                  {content.phone}
                </p>
              </div>
            </div>
            <div className="flex w-1/2 items-center gap-2">
              <div>
                <img
                  src={urlFor(content.dayIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-start ">
                <p className="monstrrate text-[.5rem] ">{content.dayLabbel}</p>
                <p className="text-[#2B3029] monstrrate text-[.6rem]">
                  {content.workingDays}
                </p>
              </div>
            </div>
          </div>
          <div className="2-row flex items-center w-full mt-8">
            <div className="flex w-1/2  items-center justify-start gap-2">
              <div>
                <img
                  src={urlFor(content.officeIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-start ">
                <p className="monstrrate text-[.5rem]">
                  {content.locationLable}
                </p>
                <p className="text-[#2B3029] monstrrate text-[.6rem]">
                  {content.address}
                </p>
              </div>
            </div>
            <div className="flex w-1/2 items-center gap-2">
              <div>
                <img
                  src={urlFor(content.emailIcon).url()}
                  alt="/"
                  className="w-7"
                />
              </div>
              <div className="flex flex-col items-start ">
                <p className="monstrrate text-[.5rem] ">{content.emailLabel}</p>
                <p className="text-[#2B3029] monstrrate text-[.6rem]">
                  {content.emailAdd}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-right w-full lg:w-2/3 flex flex-col items-start justify-center">
        <form onSubmit={handleSubmit}>
          <div className="flex items-start gap-4 lg:gap-10">
            <div className="flex flex-col items-start space-y-2 lg:space-y-4">
              <label>{content.formLabels.firstName}</label>
              <input
                type="text"
                name="firstName"
                className="lg:border-2 border-b-2 lg:rounded-full w-full h-[1.8rem] lg:h-[3.5vw] lg:w-[25vw] px-4 focus:outline-none"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col items-start space-y-2 lg:space-y-4">
              <label>{content.formLabels.lastName}</label>
              <input
                type="text"
                name="lastName"
                className="lg:border-2 border-b-2 lg:rounded-full w-full h-[1.8rem] lg:h-[3.5vw] lg:w-[25vw] px-4 focus:outline-none"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex items-start gap-4 lg:gap-10">
            <div className="flex flex-col items-start space-y-2 lg:space-y-4 mt-4 lg:mt-12">
              <label>{content.formLabels.phone}</label>
              <input
                type="tel"
                name="phone"
                className="lg:border-2 border-b-2 lg:rounded-full w-full h-[1.8rem] lg:h-[3.5vw] lg:w-[25vw] px-4 focus:outline-none"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col items-start space-y-2 lg:space-y-4 mt-4 lg:mt-12">
              <label>{content.formLabels.company}</label>
              <input
                type="text"
                name="company"
                className="lg:border-2 border-b-2 lg:rounded-full w-full h-[1.8rem] lg:h-[3.5vw] lg:w-[25vw] px-4 focus:outline-none"
                value={formData.company}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-10 mt-10">
            <div className="flex flex-col space-y-4">
              <label>{content.formLabels.message}</label>
              <textarea
                name="message"
                placeholder={content.formLabels.message}
                className="border-2 rounded-sm lg:rounded-2xl w-full min-h-[10rem] lg:min-h-[16rem] p-2 py-16 pt-2 focus:outline-none"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <div className="flex flex-col space-y-4">
              <label>{content.formLabels.email}</label>
              <input
                type="email"
                name="email"
                className="lg:border-2 border-b-2 lg:rounded-full w-full h-[1.8rem] lg:h-[3.5vw] lg:w-[25vw] px-4 focus:outline-none"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
           {/* Add other input fields similarly */}
           <div className="flex mx-auto lg:mx-0 w-fit space-y-2 lg:space-y-4 mt-6 lg:mt-5">
            {isLoading ? (
              <div className="loader text-green-300 font-medium">Submitting...</div> // Loader
            ) : (
              <button
                type="submit"
                className="bg-[#63715B] text-white text-[12px] lg:text-xl font-semibold px-4 py-2 rounded-lg mx-auto"
              >
                {content.sendBtn}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default HomeSec12;
