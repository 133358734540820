import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import DetailPage from "./DetailPage1";
import DetailPage2 from "./DetailPage2";
import HomeSec8 from "../unusable components/Home-7/HomeSec7";
import HomeSec13 from "../unusable components/Home-13/HomeSec13";
import Footer from "../home/Home-14/Footer";
import HomeSec12 from "../home/Home-12/HomeSec12";
import DeatilPage1 from "./DetailPage1";
// import TextRotator from "../TextRotator";

const ProductDetail = () => {
  const { productType } = useParams();
  return (
    <div>
      <Navbar />
       <DeatilPage1 />
       <DetailPage2 />
       <HomeSec12 />
       <Footer />
    </div>
  );
};

export default ProductDetail;
