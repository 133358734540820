import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import client, { urlFor } from '../../sanityCli';
import '../../../index.css';

function HomeHero() {
  const [homeHeroData, setHomeHeroData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideIntervalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "homeHero"][0]');
        setHomeHeroData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (homeHeroData && homeHeroData.backgroundImages && homeHeroData.backgroundImages.length > 0) {
      slideIntervalRef.current = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % homeHeroData.backgroundImages.length);
      }, 5000); // Change slide every 5 seconds

      return () => clearInterval(slideIntervalRef.current);
    }
  }, [homeHeroData]);

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  

  if (!homeHeroData) {
    return <div></div>;
  }

  return (
    <div id='home' className='home-hero relative overflow-hidden'>
      <div
        className={`flex w-[${(homeHeroData.backgroundImages.length) * 100}vw] transition-transform duration-1000 transform`}
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {homeHeroData.backgroundImages.map((image, index) => (
          <img
            key={index}
            src={urlFor(image).width(1920).url()}
            alt='/'
            className='w-[100vw] lg:h-[700px] md:h-[508px] h-[340px] object-cover'
          />
        ))}
      </div>
      <div className='overlay-text absolute lg:top-1/4 top-[2.5rem] left-[0%] px-[2%]'>
        <div className='flex flex-col items-center justify-between lg:space-y-10 space-y-4'>
          <h1 className='playfair-display lg:px-[20%] px-[0%] text-center text-white text-[1.25rem] leading-7 lg:text-[3.5vw] lg:leading-[4.5vw] tracking-wide-[6vw] font-bold'>
            {homeHeroData.title}
          </h1>
          <span className='bg-white w-32 h-1 rounded-sm'></span>
          <p className='monstrrate text-[12px] md:text-[1rem] leading-5 lg:text-[1.25vw] text-center lg:px-[10%] px-4 lg:leading-[2.6vw] text-white font-normal'>
            {homeHeroData.description}
          </p>
          <Link to='/ourproducts' className='w-fit text-white p-4 border-2 border-white rounded-sm'>
            {homeHeroData.buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
