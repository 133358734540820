import React, { useEffect, useState } from "react";
import client, { urlFor } from "../sanityCli";
import Footer from "../home/Home-14/Footer";
import Navbar from "../navbar/Navbar";

export default function CookiesPolicy() {
  const [cookiesPolicy, setCookiesPolicy] = useState(null);

  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "policyCookie"][0]');
        setCookiesPolicy(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!cookiesPolicy) {
    return <div></div>;
  }

  const {
    cookiePromiss,
    cookiePolicyTitle,
    cookiePolicyDescription,
    effectiveData,
    cookiePolicyWebsite,
    cookiePolicy,
    images
  } = cookiesPolicy;

  return (
    <>
      <Navbar />
      <div className="w-full lg:px-40 px-8 pt-4 lg:pt-0 pb-[4rem] lg:pb-[10rem] flex items-start lg:gap-40">
        <div className="md:w-3/5 w-full">
          <div className="flex flex-col gap-6 md:my-16 my-4">
            <p className="poppins capitalize lg:text-base text-sm font-medium text-[#707070]">
              {cookiePromiss}
            </p>
            <p className="monstrrate text-3xl xl:text-4xl 2xl:text-[4rem] 2xl:leading-[6rem] text-[#1e1e1e] font-semibold">
              {cookiePolicyTitle}
            </p>
            <p className="poppins lg:text-base text-sm  2xl:text-2xl font-normal text-[#4E4E4E]-800">
              {cookiePolicyDescription}
            </p>
            <p className="flex flex-col lg:flex-row  items-start lg:items-center gap-4 lg:gap-8">
              <span className="text-base lg:text-xl  2xl:text-2xl capitalize text-[#000000]">
                {effectiveData}
              </span>
              <span className="text-base lg:text-xl  2xl:text-2xl capitalize text-[#000000]">
                {cookiePolicyWebsite}
              </span>
            </p>
          </div>
          <div className="flex flex-col mt-[2rem] lg:mt-[10rem]">
            {cookiePolicy.map((policyItem, index) => (
              <div key={index} className="mb-8">
                <p className="monstrrate text-2xl lg:text-4xl font-semibold text-[#1E1E1E] capitalize lg:mb-[2rem]">
                  {policyItem.title}
                </p>
                {policyItem.subTitle.map((subItem, subIndex) => (
                  <p
                    key={subIndex}
                    className="poppins text-base lg:text-lg  2xl:text-2xl font-normal text-[#4E4E4E] my-6 lg:my-8"
                  >
                    {subItem}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="md:w-1/5 h-full md:block hidden">
          {images?.firstImg && (
            <img src={urlFor(images.firstImg)} alt="Pen" className="ml-8 mt-10 w-full" />
          )}
          {images?.secondImg && (
            <img src={urlFor(images.secondImg)} alt="Line" className="w-full mt-[-1rem]" />
          )}
          {images?.thirdImg && (
            <img src={urlFor(images.thirdImg)} alt="Home" className="w-full mt-[-2rem]" />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
