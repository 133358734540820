import React, { useState, useEffect } from "react";
import client from "../../sanityCli";
import { useNavigate } from "react-router-dom";

function HomeSec5() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec5"][0]{
          heading,
          title,
          products[]{
            image{
              asset->{
                _id,
                url
              }
            },
            icon{
              asset->{
                _id,
                url
              }
            },
            label
          }
        }`
      )
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  const handleNavigationClick = (index) => {
    const productTypes = ["Denim", "Suiting", "Shirting", "T-shirt", "Garments"];
    if (productTypes[index]) {
      navigate(`/product/${productTypes[index]}`);
      setActiveIndex(index);
    }
  };

  if (error) return <div>Error loading data</div>;
  if (!data) return <div></div>;

  return (
    <div
      id="ourProducts"
      className="px-6 py-10 lg:py-16 lg:px-20 flex flex-col items-center bg-gray-50"
    >
      {/* Heading Section */}
      <div className="flex flex-col items-center justify-center space-y-4 lg:space-y-10">
        <p className="monstrrate text-[#6D7A65] text-base font-semibold leading-10 lg:text-[1.75vw] lg:eading-[2.75vw]">
          {data.heading}
        </p>
        <h1 className="text-[#2D2D2D] text-2xl playfair-display font-semibold lg:text-[3.75vw] lg:leading-[5.25vw] text-center">
          {data.title}
        </h1>
      </div>

      {/* Products Section */}
      <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-8 w-full">
        {data.products &&
          data.products.map((product, index) => (
            <div
              key={index}
              className={`relative bg-white shadow-lg hover:shadow-xl rounded-lg overflow-hidden transition-transform transform  cursor-pointer ${
                activeIndex === index ? "ring-4 ring-green-300" : ""
              }`}
              onClick={() => handleNavigationClick(index)}
            >
              {/* Product Image */}
              <img
                src={product.image.asset.url}
                alt={product.label}
                className="w-full h-56 lg:h-96 object-cover"
              />

              {/* Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-10 hover:bg-opacity-25 transition-all"></div>

              {/* Icon and Label */}
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white rounded-full pl-2 py-2 pr-4 flex items-center gap-3 transition-transform hover:scale-105">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#6D7A65]">
                  <img
                    src={product.icon.asset.url}
                    alt={product.label}
                    className="w-6 h-6"
                  />
                </div>
                <p className="text-gray-700 text-lg font-bold">
                  {product.label}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default HomeSec5;
