import React from "react";
import Navbar from "../navbar/Navbar";
import HomeSec12 from "../home/Home-12/HomeSec12";
import Footer from "../home/Home-14/Footer";
import DeatilPage1 from "../DetailProject/DetailPage1";
import DetailPage2 from "../DetailProject/DetailPage2";

function Products() {
  return (
    <div>
      <Navbar />
      <DeatilPage1 />
      <DetailPage2 />
      <HomeSec12 />
      <Footer />
    </div>
  );
}

export default Products;
