import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import client, { urlFor } from "../../sanityCli";
import { v4 as uuid4 } from "uuid";

function Footer() {
  const [content, setContent] = useState(null);
  const [footer, setFooter] = useState(null);
  const [emailData, setEmailData] = useState({ email: "" });

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const footerResult = await client.fetch('*[_type == "footer"][0]');
        setFooter(footerResult);

        const contentResult = await client.fetch(
          '*[_type == "newsletterSubscription"][0]'
        );
        setContent(contentResult);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchFooterData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content) return;

    try {
      await client
        .patch(content._id)
        .setIfMissing({ subscribers: [] })
        .append("subscribers", [{ ...emailData, _key: uuid4() }])
        .commit();
      alert("Subscribed successfully!");
      setEmailData({ email: "" });
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!footer) return null;

  return (
    <footer className="bg-[#F1F2F0] px-[5%] py-[5%]">
      {/* Desktop Footer */}
      <div className="hidden md:flex justify-between">
        {/* Company Section */}
        {footer.company && (
          <div>
            <h1 className="monstrrate text-[1vw] font-semibold text-gray-800">
              {footer.company.title}
            </h1>
            <div className="mt-4 space-y-2">
              {[footer.company.itme1, footer.company.itme2, footer.company.itme3].map(
                (item, index) =>
                  item && (
                    <p
                      key={index}
                      className="monstrrate text-[1vw] text-gray-800 cursor-pointer"
                      onClick={() => handleNavLinkClick("about")}
                    >
                      {item}
                    </p>
                  )
              )}
            </div>
          </div>
        )}

        {/* Get In Touch Section */}
        {footer.getInTouch && (
          <div>
            <h1 className="monstrrate text-[1vw] font-semibold text-gray-800">
              {footer.getInTouch.title}
            </h1>
            <p
              className="monstrrate text-[1vw] text-gray-800 cursor-pointer mt-4"
              onClick={() => handleNavLinkClick("contact")}
            >
              {footer.getInTouch.itme1}
            </p>
          </div>
        )}

        {/* Logo and Legal Section */}
        <div>
          {footer.expoLogo && (
            <img
              src={urlFor(footer.expoLogo)}
              alt="Expo Logo"
              className="w-[10vw] cursor-pointer mb-4"
              onClick={() => handleNavLinkClick("home")}
            />
          )}
          {footer.copyright && (
            <p className="monstrrate text-[1vw] text-gray-800">
              {footer.copyright}
            </p>
          )}
          <div className="flex gap-4 mt-4">
            {footer.legals && footer.legals.itme2Path && (
              <Link
                to={footer.legals.itme2Path}
                className="monstrrate text-[1vw] text-gray-800 underline"
              >
                {footer.legals.itme2}
              </Link>
            )}
            {footer.legals && footer.legals.itme3Path && (
              <Link
                to={footer.legals.itme3Path}
                className="monstrrate text-[1vw] text-gray-800 underline"
              >
                {footer.legals.itme3}
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Footer */}
      <div className="md:hidden flex flex-col items-center space-y-6">
        {footer.expoLogo && (
          <img
            src={urlFor(footer.expoLogo)}
            alt="Expo Logo"
            className="w-[40vw] cursor-pointer"
            onClick={() => handleNavLinkClick("home")}
          />
        )}
        {footer.company && (
          <div className="text-center">
            <h1 className="monstrrate text-[4vw] font-semibold text-gray-800">
              {footer.company.title}
            </h1>
            <div className="mt-4 space-y-2">
              {[footer.company.itme1, footer.company.itme2, footer.company.itme3].map(
                (item, index) =>
                  item && (
                    <p
                      key={index}
                      className="monstrrate text-[4vw] text-[#313131] cursor-pointer"
                      onClick={() => handleNavLinkClick("about")}
                    >
                      {item}
                    </p>
                  )
              )}
            </div>
          </div>
        )}
        {footer.getInTouch && (
          <div>
            <h1 className="monstrrate text-[4vw] font-semibold text-gray-800">
              {footer.getInTouch.title}
            </h1>
            <p
              className="monstrrate text-[4vw] text-[#313131] cursor-pointer mt-4"
              onClick={() => handleNavLinkClick("contact")}
            >
              {footer.getInTouch.itme1}
            </p>
          </div>
        )}
        {footer.copyright && (
          <p className="monstrrate text-[4vw] text-[#313131]">
            {footer.copyright}
          </p>
        )}
      </div>
    </footer>
  );
}

export default Footer;
