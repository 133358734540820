import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import client, { urlFor } from "../sanityCli";
import arrow from "./arrow.svg";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [navbarData, setNavbarData] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navbarResult = await client.fetch(
          '*[_type == "navbarContent"][0]'
        );
        setNavbarData(navbarResult);
      } catch (error) {
        console.error("Error fetching navbar data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    closeMobileMenu(); // Close the mobile menu on nav link click
  };

  const handleProductNavClick = (productType, sectionId) => {
    navigate(`/product/${productType}`);
    if (sectionId) {
      // Set a timeout to give the page time to load before scrolling
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000); // Adjust the delay time as needed
    }
    closeDropdown(); // Close the dropdown on item click (desktop)
  };

  return (
    <nav
      id="navbar"
      className="navbar sticky top-0 z-10 flex items-center justify-between py-2 lg:py-0 px-6 md:px-10 lg:px-16 2xl:px-24 bg-white shadow-md"
    >
      <div className="navbar-logo">
        {navbarData && (
          <Link to="/">
            <img
              src={urlFor(navbarData.logo)}
              className="lg:w-[8rem] w-32 h-12"
              alt="Logo"
            />
          </Link>
        )}
      </div>
      <div className="md:hidden hamburger-button">
        <button onClick={toggleMobileMenu}>
          {navbarData && (
            <img
              src={urlFor(navbarData.hamburgerBtn)}
              className="w-[2rem]"
              alt="Menu"
            />
          )}
        </button>
      </div>
      {/* Desktop header */}
      <div className="hidden md:flex items-center space-x-4 xl:space-x-8 py-4">
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("about")}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.aboutUs}
          </p>
        )}

        {navbarData && (
          <div
            onMouseEnter={openDropdown}
            onMouseLeave={closeDropdown}
            className="relative"
          >
            <p className="text-black flex items-center gap-1 uppercase text-base cursor-pointer">
              {navbarData.ourProducts}
              <img
                src={arrow}
                alt="/"
                className={`transform transition-transform duration-500 ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
              />
            </p>
            {isDropdownOpen && (
              <div
                className="absolute top-full left-[-2] w-44 bg-white shadow-md
              flex flex-col rounded-md z-10"
              >
                <Link
                  to="/product/Denim"
                  onClick={() =>
                    handleProductNavClick("Denim", "productDetail")
                  }
                  className="flex items-center gap-2 cursor-pointer px-4 py-2 hover:bg-gray-200 text-[1rem] monstrrate font-normal hover:text-[#7D8B76] hover:font-semibold"
                >
                  <img
                    src={urlFor(navbarData.denimIcon)}
                    alt="/"
                    className="w-6 h-6"
                  />
                  <span >
                    {navbarData.denim}
                  </span>
                </Link>
                <Link
                  to="/product/Shirting"
                  onClick={() =>
                    handleProductNavClick("Suiting", "productDetail")
                  }
                   className="flex items-center gap-2 cursor-pointer px-4 py-2 hover:bg-gray-200 text-[1rem] monstrrate font-normal hover:text-[#7D8B76] hover:font-semibold"
                >
                  <img
                    src={urlFor(navbarData.shirtingIcon)}
                    alt="/"
                    className="w-6 h-6"
                  />
                  <span>
                    {navbarData.shirting}
                  </span>
                </Link>
                <Link
                  to="/product/Suiting"
                  onClick={() =>
                    handleProductNavClick("Shirting", "productDetail")
                  }
                  className="flex items-center gap-2 cursor-pointer px-4 py-2 hover:bg-gray-200 text-[1rem] monstrrate font-normal hover:text-[#7D8B76] hover:font-semibold"
                >
                  <img
                    src={urlFor(navbarData.suitingIcon)}
                    alt="/"
                    className="w-6 h-6"
                  />
                  <span className="">
                    {navbarData.suiting}
                  </span>
                </Link>
                <Link
                  to="/product/T-shirt"
                  onClick={() =>
                    handleProductNavClick("T-shirt", "productDetail")
                  }
                  className="flex items-center gap-2 cursor-pointer px-4 py-2 hover:bg-gray-200"
                >
                  <img
                    src={urlFor(navbarData.tshirtIcon)}
                    alt="/"
                    className="w-6 h-6"
                  />
                  <span className="text-[1rem] monstrrate font-normal hover:text-[#7D8B76] hover:font-semibold">
                    {navbarData.tshirt}
                  </span>
                </Link>
                <Link
                  to="/product/Garments"
                  onClick={() =>
                    handleProductNavClick("Garments", "productDetail")
                  }
                   className="flex items-center gap-2 cursor-pointer px-4 py-2 hover:bg-gray-200 text-[1rem] monstrrate font-normal hover:text-[#7D8B76] hover:font-semibold"
                >
                  <img
                    src={urlFor(navbarData.garmentsIcon)}
                    alt="/"
                    className="w-6 h-6"
                  />
                  <span >
                    {navbarData.garments}
                  </span>
                </Link>
              </div>
            )}
          </div>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("whyUs")}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.whyUs}
          </p>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("contact")}
            className="text-[#63715B] hover:bg-[#63715B] hover:text-[#fff] uppercase text-base cursor-pointer px-4 py-2 border-2 border-[#63715B] rounded-sm"
          >
            {navbarData.contact}
          </p>
        )}
      </div>

      {/* Mobile header */}
      {isMobileMenuOpen && (
        <div className="mobile-menu md:hidden absolute top-0 right-0 z-10 bg-white px-4 w-full shadow-lg">
          <div className="flex flex-col gap-8 pt-6 pb-6 px-2 mb-10">
            <div className="flex items-center justify-between">
              {navbarData && (
                <Link to="/">
                  <img
                    src={urlFor(navbarData.logo)}
                    className="w-28 h-12"
                    alt="Logo"
                  />
                </Link>
              )}
              <button onClick={toggleMobileMenu} className="rounded-lg">
                {navbarData && (
                  <img
                    src={urlFor(navbarData.hamburgerBtn2)}
                    className="w-8 z-20"
                    alt="Close"
                  />
                )}
              </button>
            </div>
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("about")}
                className="text-black uppercase text-xl font-medium cursor-pointer"
              >
                {navbarData.aboutUs}
              </p>
            )}

            {navbarData && (
              <div className="relative">
                <p
                  className="text-black flex items-center gap-1 uppercase text-xl font-medium cursor-pointer"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {navbarData.ourProducts}
                  <img
                    src={arrow}
                    alt="/"
                    className={`transform transition-transform duration-300 ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                  />
                </p>
                {isDropdownOpen && (
                  <div className="flex flex-col mt-2">
                    <Link
                      to="/product/Denim"
                      onClick={() =>
                        handleProductNavClick("Denim", "productDetail")
                      }
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center gap-4"
                    >
                      <img
                        src={urlFor(navbarData.denimIcon)}
                        alt="/"
                        className="hover:hidden"
                      />
                      <span>{navbarData.denim}</span>
                    </Link>
                    <Link
                      to="/product/Suiting"
                      onClick={() =>
                        handleProductNavClick("Suiting", "productDetail")
                      }
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center gap-4"
                    >
                      <img
                        src={urlFor(navbarData.suitingIcon)}
                        alt="/"
                        className="hover:hidden"
                      />
                      <span>{navbarData.suiting}</span>
                    </Link>
                    <Link
                      to="/product/Shirting"
                      onClick={() =>
                        handleProductNavClick("Shirting", "productDetail")
                      }
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center gap-4"
                    >
                      <img
                        src={urlFor(navbarData.shirtingIcon)}
                        alt="/"
                        className="hover:hidden"
                      />
                      <span>{navbarData.shirting}</span>
                    </Link>
                    <Link
                      to="/product/T-shirt"
                      onClick={() =>
                        handleProductNavClick("T-shirt", "productDetail")
                      }
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center gap-4"
                    >
                      <img
                        src={urlFor(navbarData.tshirtIcon)}
                        alt="/"
                        className="hover:hidden"
                      />
                      <span>
                        {navbarData.tshirt}
                      </span>
                    </Link>
                    <Link
                      to="/product/Garments"
                      onClick={() =>
                        handleProductNavClick("Garments", "productDetail")
                      }
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200 flex items-center gap-4"
                    >
                      <img
                        src={urlFor(navbarData.garmentsIcon)}
                        alt="/"
                        className="hover:hidden"
                      />
                      <span>
                        {navbarData.garments}
                      </span>
                    </Link>
                  </div>
                )}
              </div>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("whyUs")}
                className="text-black uppercase text-xl font-medium cursor-pointer"
              >
                {navbarData.whyUs}
              </p>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("contact")}
                className="text-[#63715B] hover:bg-[#63715B] hover:text-[#fff] uppercase w-fit text-base cursor-pointer px-4 py-2 border-2 border-[#63715B] rounded-sm"
              >
                {navbarData.contact}
              </p>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
