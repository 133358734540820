import React from 'react'
import Navbar from './components/navbar/Navbar'
import HomeComponents from './components/home/HomeComponents'

function App() {
  return (
    <div className=''>
        <HomeComponents />
    </div>
  )
}

export default App;