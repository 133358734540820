import React, { useState, useEffect } from "react";
import client, { urlFor } from "../../sanityCli";
// import "./HomeSec2.css";

function HomeSec2() {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeSec2"][0]{
          sectionTitle,
          mainTitle,
          description,
          vision1Title,
          vision1Description,
          vision2Title,
          vision2Description,
          "image1Url": image1.asset->url,
          "image2Url": image2.asset->url,
          "image3Url": image3.asset->url,
          "image4Url": image4.asset->url,
          "imageBoxUrl": imageBox.asset->url
        }`
      )
      .then((data) => setData(data))
      .catch(console.error);
  }, []);

  if (!data) return <div></div>;

  return (
    <div
      id="about"
      className="about-us flex flex-col-reverse lg:flex-row items-center gap-4 lg:gap-10 m-[5%] my-[5%] py-[2%]"
    >
      <div className="about-left w-full lg:w-1/2 flex flex-col space-y-6 lg:space-y-8">
        <div className=" hidden lg:flex flex-col text-center space-y-10 lg:text-left">
          <p className="text-[#6D7A65] text-[4vw] md:text-[2.5vw] lg:text-[1.75vw] leading-[5vw] md:leading-[3.5vw] lg:leading-[2.4vw] font-semibold">
            {data.sectionTitle}
          </p>
          <h1 className="text-[#2B3029] playfair-display text-[6vw] md:text-[4.5vw] lg:text-[3.25vw] leading-[3vw] md:leading-[2.5vw] lg:leading-[2vw] font-semibold capitalize tracking-wider-[5%]">
            {data.mainTitle}
          </h1>
          <p className="text-[#2D2D2D] text-base monstrrate font-medium leading-7 md:text-[2vw] lg:text-[1.25vw]  md:leading-[3vw] lg:leading-[2vw]">
            {data.description}
          </p>
        </div>
        <div className="about-left-box flex flex-col md:flex-row items-start gap-6 md:gap-15">
          <div
            className="w-full md:w-1/2 flex flex-col items-center md:items-start space-y-4 rounded-xl p-[4%] lg:py-4 py-[6%] shadow-xl"
            style={{ backgroundColor: "#63715B" }}
          >
            {data.image1Url && <img src={data.image1Url} alt="Vision 1" className="w-[4rem]" />}

            <h2 className="text-[#E4E7E0] text-[2rem] monstrrate md:text-[3vw] lg:text-[1.75vw] font-bold text-center md:text-left">
              {data.vision1Title}
            </h2>
            <p className="text-[#E4E7E0] text-lg leading-8 font-bold md:text-[2vw] lg:text-[1vw]  md:leading-[3vw] lg:leading-[2vw] text-center md:text-left">
              {data.vision1Description}
            </p>
          </div>
          <div className=" bg-[#E4E7E0]  lg:min-h-[15rem] w-full md:w-1/2 flex flex-col items-center md:items-start space-y-4 rounded-xl p-[4%] lg:py-4 py-[6%] shadow-xl">
           
          <img src={urlFor(data.image2Url)} alt="Vision 2" className="w-[4rem]" />
            <h2 className="text-[#2B3029] text-[2rem] monstrrate md:text-[3vw] lg:text-[1.75vw] font-bold text-center md:text-left">
              {data.vision2Title}
            </h2>
            <p className="text-[#2B3029] text-lg leading-8 font-bold md:text-[2vw] lg:text-[1vw]  md:leading-[3vw] lg:leading-[2vw] text-center md:text-left">
              {data.vision2Description}
            </p>
          </div>
        </div>
      </div>
    
      <div className="about-right w-full lg:w-1/2 flex flex-col items-center justify-center">
        <div className="flex w-full">
          {data.image3Url && (
            <img src={data.image3Url} alt="Image 3" className="w-1/2" />
          )}
          {data.image4Url && (
            <img src={data.image4Url} alt="Image 4" className="w-1/2" />
          )}
        </div>
        <div className="w-full mt-4">
          {data.imageBoxUrl && (
            <img src={data.imageBoxUrl} alt="Image Box" className="w-full" />
          )}
        </div>
      </div>
      <div className=" block lg:hidden flex-col space-y-6 text-center lg:text-left">
        <p className="text-[#6D7A65] text-[4vw] md:text-[2.5vw] lg:text-[1.75vw] leading-[5vw] md:leading-[3.5vw] lg:leading-[2.4vw] font-semibold">
          {data.sectionTitle}
        </p>
        <h1 className="text-[#2B3029] playfair-display text-[6vw] md:text-[4.5vw] lg:text-[3.5vw] leading-[3vw] md:leading-[2.5vw] lg:leading-[2vw] font-semibold capitalize tracking-wider-[5%]">
          {data.mainTitle}
        </h1>
        <p className="text-[#2D2D2D] text-[12px] monstrrate font-medium leading-7 md:text-[2vw] lg:text-[1.25vw]  md:leading-[3vw] lg:leading-[2vw]">
          {data.description}
        </p>
      </div>
    </div>
  );
}

export default HomeSec2;
