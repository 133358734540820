import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import image from './Rectangle.png';

function HomeSec13() {

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the delay time as needed
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

  };


  return (
    <div className="my-[10%] mx-[5%] flex lg:flex-row flex-col-reverse w-[90%] icon-bg rounded-xl border-2 border-green-200 ">
      <div className="lg:pl-8 py-8 flex flex-col items-start w-full lg:w-[75%] space-y-4 lg:space-y-8">
        <h1 className="playfair-display font-bold px-[2%] lg:px-0  text-[1.5rem] text-center lg:text-start lg:text-[3.75vw] lg:leading-[5vw] text-white">Save upto 50% this Holiday season</h1>
        <p className="monstrrate text-[#F9F9F9] text-center lg:text-start font-medium text-[1rem] leading-6 lg:text-[1.5vw] lg:leading-[2.2vw] px-6 lg:px-0">
          It’s time to revamp your fashion game without breaking the bank! Dive
          into our exclusive 50% off sale and discover unbearable deals on the
          most coveted styles.
        </p>
        <button className="text-white border-[1px] border-white px-4 lg:px-8 py-1 mx-auto lg:mx-0" onClick={(() => handleNavLinkClick('contact'))}>Shop Now</button>
      </div>
      <div className="w-full lg:w-[25%]">
         <img src={image} alt="/" className="w-full"/> 
      </div>
    </div>
  );
}

export default HomeSec13;
